// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./slider.css";

// import required modules
import { EffectCoverflow, Pagination } from "swiper/modules";
import icpHome from "../assets/icpHome.png";
import Nextjsicon from "../assets/nextjsIcon.png";
// import img1 from "../assets/img1.png";
import HamdiyaMd from "../assets/HamdiyaMd.png";
import t4Home from "../assets/slider/cleanIraq/t4Home.png";
import tailwind from "../assets/tailwind.png";
import vercelIcon from "../assets/vercelIcon.png";
import tsIcon from "../assets/tsIcon.png";
import netlifyIcon from "../assets/netlifyIcon.png";

export default function Slider() {
  let projectsList = [
    {
      id: "0",
      title: "Architecture And Artest Portfolio",
      image: HamdiyaMd,
      WebsiteLink: "https://hamdiya.co/en",
      GithubLink:
        "https://github.com/MayceAlazzawi/Mina-Portfolio-Next-Tailwind",
      tech: [Nextjsicon, tsIcon, vercelIcon, tailwind],
    },
    {
      id: "1",
      title: "Iraqi Competitive Programming",
      image: icpHome,
      WebsiteLink: "https://icp.murt.me",
      GithubLink: "",
      tech: [Nextjsicon, tsIcon, netlifyIcon, tailwind],
    },
    {
      id: "3",
      title: "Project Manger on the Meta Frontend Development course",
      image: t4Home,
      WebsiteLink: "hhttps://clean-iraq-team-4.netlify.app/",
      GithubLink: "https://github.com/nody-iq/clean_iraq_campaign",
      tech: [Nextjsicon, tsIcon, netlifyIcon, tailwind],
    },
  ];
  return (
    <section>
      <div className="font-medium text-sm text-center text-light">
        <h1 className="">My Recent Work</h1>
        <h2 className="text-primary text-3xl text-center">Portfolio</h2>
      </div>
      <div className=" flex justify-center items-center">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            stretch: 0,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
          className="mySwiper pb-[115px] pt-[30px] rounded-lg"
        >
          {projectsList.map((project) => (
            <SwiperSlide
              key={project.id}
              className="w-[300px] h-[270px] sm:w-[675px] sm:h-[430px] rounded-lg relative"
            >
              <a href={project.WebsiteLink}>
                <img src={project.image} alt="project" />
              </a>
              <div className="gap-2 flex justify-center items-center flex-col absolute inset-0 bg-opacity-75 bg-black text-white opacity-0 hover:opacity-100 transition-opacity duration-300 hover:rounded-lg">
                <a
                  href={project.WebsiteLink}
                  target="_blank"
                  rel="noreferrer"
                  className="bg-white text-black pt-2 py-4 px-4 rounded mt-4 font-normal"
                >
                  Visit Website
                </a>
                <a
                  href={project.GithubLink}
                  className="text-gray-300 rounded font-normal m-1"
                  target="_blank"
                  rel="noreferrer"
                >
                  {/* Visit  */}
                  <svg
                    class="w-10 h-10"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
                <span className="font-bold">Technologies used:</span>
                <div className="tech flex flex-row gap-5">
                    <img
                      key={project.id}
                      src={project.tech[0]}
                      alt={project.id}
                      width={1}
                      height={1}
                      className="!w-[33px] !h-[33px] "
                    />
                    <img
                      key={project.id}
                      src={project.tech[1]}

                      alt={project.id}
                      width={1}
                      height={1}
                      className="!w-[30px] !h-[30px] "
                    />
                    <img
                      key={project.id}
                      src={project.tech[2]}

                      alt={project.id}
                      width={1}
                      height={1}
                      className="!w-[33px] !h-[33px] "
                    />
                </div>
              </div>
              <div className="flex justify-center items-center flex-col py-2">
                <span className="tracking-[0.1rem] text-lg text-center">
                  {project.title}
                </span>
                <div className="flex flex-col justify-center  items-center text-center">
                  <a
                    href={project.WebsiteLink}
                    target="_blank"
                    rel="noreferrer"
                    className="text-gray-500 font-light"
                  >
                    {project.WebsiteLink.slice(8)}
                  </a>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
}
